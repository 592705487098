/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const conUpdateUpload = /* GraphQL */ `
  subscription ConUpdateUpload($owner: String) {
    conUpdateUpload(owner: $owner) {
      id
      key
      fileName
      owner
      plan {
        id
        owner
        coins
        planCoins
        createdAt
        updatedAt
      }
      transcriptStatus
      createdAt
      updatedAt
    }
  }
`;
export const conCreatePlan = /* GraphQL */ `
  subscription ConCreatePlan($owner: String) {
    conCreatePlan(owner: $owner) {
      id
      owner
      coins
      planCoins
      coupon {
        id
        owner
        tokens
        valid
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      uploads {
        nextToken
      }
    }
  }
`;
export const onCreatePlan = /* GraphQL */ `
  subscription OnCreatePlan($owner: String!) {
    onCreatePlan(owner: $owner) {
      id
      owner
      coins
      planCoins
      coupon {
        id
        owner
        tokens
        valid
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      uploads {
        nextToken
      }
    }
  }
`;
export const onUpdatePlan = /* GraphQL */ `
  subscription OnUpdatePlan($owner: String!) {
    onUpdatePlan(owner: $owner) {
      id
      owner
      coins
      planCoins
      coupon {
        id
        owner
        tokens
        valid
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      uploads {
        nextToken
      }
    }
  }
`;
export const onDeletePlan = /* GraphQL */ `
  subscription OnDeletePlan($owner: String!) {
    onDeletePlan(owner: $owner) {
      id
      owner
      coins
      planCoins
      coupon {
        id
        owner
        tokens
        valid
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      uploads {
        nextToken
      }
    }
  }
`;
export const onCreateCoupon = /* GraphQL */ `
  subscription OnCreateCoupon {
    onCreateCoupon {
      id
      owner
      tokens
      valid
      plan {
        id
        owner
        coins
        planCoins
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCoupon = /* GraphQL */ `
  subscription OnUpdateCoupon {
    onUpdateCoupon {
      id
      owner
      tokens
      valid
      plan {
        id
        owner
        coins
        planCoins
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCoupon = /* GraphQL */ `
  subscription OnDeleteCoupon {
    onDeleteCoupon {
      id
      owner
      tokens
      valid
      plan {
        id
        owner
        coins
        planCoins
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUpload = /* GraphQL */ `
  subscription OnCreateUpload($owner: String) {
    onCreateUpload(owner: $owner) {
      id
      key
      fileName
      owner
      plan {
        id
        owner
        coins
        planCoins
        createdAt
        updatedAt
      }
      transcriptStatus
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUpload = /* GraphQL */ `
  subscription OnUpdateUpload($owner: String) {
    onUpdateUpload(owner: $owner) {
      id
      key
      fileName
      owner
      plan {
        id
        owner
        coins
        planCoins
        createdAt
        updatedAt
      }
      transcriptStatus
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUpload = /* GraphQL */ `
  subscription OnDeleteUpload($owner: String) {
    onDeleteUpload(owner: $owner) {
      id
      key
      fileName
      owner
      plan {
        id
        owner
        coins
        planCoins
        createdAt
        updatedAt
      }
      transcriptStatus
      createdAt
      updatedAt
    }
  }
`;
