import React, { useEffect, useState } from 'react'
import { TranscriptEditor } from "@bbc/react-transcript-editor";
import helpers from '../utilities/Helpers'
import {Form , Button} from 'react-bootstrap';
import './Editor.css'
import Swal from 'sweetalert2'
import * as moment from 'moment'
import ReactDOM from 'react-dom';

//tag manager
import TagManager from 'react-gtm-module'

function Editor(){
  const [edit,setEdit] = useState(false)
  const tagManagerArgs = {
    dataLayer: {
        page: 'editor'
    },
    dataLayerName: 'PageDataLayer'
  }
  TagManager.dataLayer(tagManagerArgs)

  const [transcript,setTranscript] = useState("")
  const [uploads,setUploads] = useState([])
  const [mediaUrl,setMediaUrl] = useState("")
  const [test,setTest] = useState("")
  var url_pattern = "/(http[s]?:\/\/)?([^\/\s]+\/)(.*)/";

  const isUrlValid = (event) => {
    
    event.preventDefault();
    const form = event.target.elements 
    if(form.TranscriptOption.value === "0"){
      Swal.fire(
        'Not valid transcript!',
        'Select a transcript',
        'warning'
      )
      return
    }
    if(form.mediaURL.value.match(url_pattern)){
      setMediaUrl(form.mediaURL.value)
         
    }else{
      Swal.fire(
        'Not valid url!',
        'Copy public mp3 url',
        'warning'
      )
    }
  }
  
  async function getUploads(){
    const uploads = await helpers.listUploads()
    const uploadsTranscripted = uploads.filter(x => x.transcriptStatus == 1) 
    uploadsTranscripted.sort((a,b) => moment(b.createdAt).format('YYYYMMDD') - moment(a.createdAt).format('YYYYMMDD'))
    setUploads(uploadsTranscripted)
  }
  const handleChange = async (value) => {
    console.log('new value ' + value)    
    const trans = await helpers.getTranscript(value.target.value)
    setTranscript(trans)      
    
  }
  useEffect(() => {
    getUploads()
    setEdit(true)
  },['uploads'])
  
  return(
    <div>
   
    <div className="Editor__container mt-5 mb-3">      
      <p className="mt-5 Editor__intro">
        Choose your transcript and public mp3 url from your hosting service
        so that you can start editing your transcript
      </p>      
    {
      uploads ? 
      (
    <Form onSubmit={isUrlValid}>
    
      <Form.Group >        
        <Form.Control as="select" id="TranscriptOption" custom
        onChange={(e) => handleChange(e)}
        className="mb-3"      
        >
        <option key="0" value="0">Select transcript</option>
        {
          (uploads.map((item,index) => {
          return (                
            <option key={index} value={item.key}>{item.fileName.substring(0, 24) + ' transcript'}</option>
            )
          })) 
        }

        </Form.Control>
        <Form.Control type="text" id="mediaURL" placeholder="Enter mp3 url" />
        
      </Form.Group>
      <Button variant="primary mb-5" type="submit" >
      Editor
    </Button>
    </Form>
    
      ) : null
    }
    </div>    
    <TranscriptEditor
    transcriptData={transcript}
    mediaUrl={mediaUrl}
    isEditable={edit}
    spellCheck={true}
    sttJsonType={"amazontranscribe"}
    mediaType="audio"
    className="TranscriptEditor"
    handleAnalyticsEvents={() => {}}
    />
    </div> 
  )
}

export default Editor;