/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPlan = /* GraphQL */ `
  mutation CreatePlan(
    $input: CreatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    createPlan(input: $input, condition: $condition) {
      id
      owner
      coins
      planCoins
      coupon {
        id
        owner
        tokens
        valid
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      uploads {
        nextToken
      }
    }
  }
`;
export const updatePlan = /* GraphQL */ `
  mutation UpdatePlan(
    $input: UpdatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    updatePlan(input: $input, condition: $condition) {
      id
      owner
      coins
      planCoins
      coupon {
        id
        owner
        tokens
        valid
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      uploads {
        nextToken
      }
    }
  }
`;
export const deletePlan = /* GraphQL */ `
  mutation DeletePlan(
    $input: DeletePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    deletePlan(input: $input, condition: $condition) {
      id
      owner
      coins
      planCoins
      coupon {
        id
        owner
        tokens
        valid
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      uploads {
        nextToken
      }
    }
  }
`;
export const createUpload = /* GraphQL */ `
  mutation CreateUpload(
    $input: CreateUploadInput!
    $condition: ModelUploadConditionInput
  ) {
    createUpload(input: $input, condition: $condition) {
      id
      key
      fileName
      owner
      plan {
        id
        owner
        coins
        planCoins
        createdAt
        updatedAt
      }
      transcriptStatus
      createdAt
      updatedAt
    }
  }
`;
export const deleteUpload = /* GraphQL */ `
  mutation DeleteUpload(
    $input: DeleteUploadInput!
    $condition: ModelUploadConditionInput
  ) {
    deleteUpload(input: $input, condition: $condition) {
      id
      key
      fileName
      owner
      plan {
        id
        owner
        coins
        planCoins
        createdAt
        updatedAt
      }
      transcriptStatus
      createdAt
      updatedAt
    }
  }
`;
export const createCoupon = /* GraphQL */ `
  mutation CreateCoupon(
    $input: CreateCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    createCoupon(input: $input, condition: $condition) {
      id
      owner
      tokens
      valid
      plan {
        id
        owner
        coins
        planCoins
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCoupon = /* GraphQL */ `
  mutation UpdateCoupon(
    $input: UpdateCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    updateCoupon(input: $input, condition: $condition) {
      id
      owner
      tokens
      valid
      plan {
        id
        owner
        coins
        planCoins
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCoupon = /* GraphQL */ `
  mutation DeleteCoupon(
    $input: DeleteCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    deleteCoupon(input: $input, condition: $condition) {
      id
      owner
      tokens
      valid
      plan {
        id
        owner
        coins
        planCoins
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUpload = /* GraphQL */ `
  mutation UpdateUpload(
    $input: UpdateUploadInput!
    $condition: ModelUploadConditionInput
  ) {
    updateUpload(input: $input, condition: $condition) {
      id
      key
      fileName
      owner
      plan {
        id
        owner
        coins
        planCoins
        createdAt
        updatedAt
      }
      transcriptStatus
      createdAt
      updatedAt
    }
  }
`;
