import React from 'react';
import './App.css';
import Amplify from 'aws-amplify';
import {AmplifyAuthenticator, AmplifySignUp,AmplifySignOut, AmplifySignIn , AmplifyForgotPassword} from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import awsconfig from './aws-exports';
import App from './App'
import R from './images/R.png'
import Menu from './components/Menu'
import Editor from './components/Editor'
import AudioSample from './components/AudioSample'

import './AuthStateApp.css'
import { Navbar,Nav,Form,FormControl,Button } from 'react-bootstrap';
import {
  HashRouter,
  Switch,
  Route
} from "react-router-dom";
import TeamSection from './components/TeamSection';

import SitePlan from './components/SitePlan'

//tag manager config

import TagManager from 'react-gtm-module'
import PromoVideo from './components/PromoVideo';
import Newsletter from './components/Newsletter';
import BigFooter from './components/BigFooter';
import ContactForm from './components/ContactForm';

const tagManagerArgs = {
  gtmId: 'GTM-WS3Z4S2'
}

TagManager.initialize(tagManagerArgs)

Amplify.configure(awsconfig);

function AuthStateApp() {
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  React.useEffect(() => {
      return onAuthUIStateChange((nextAuthState, authData) => {
          setAuthState(nextAuthState);
          setUser(authData)
      });
  }, []);
  
  return authState === AuthState.SignedIn && user ? (
    <div className="App">
    <HashRouter>        
    <Switch>
      <Route path="/editor">
        <Menu username={user.username}/>
        <Editor />
      </Route>
      <Route path="/">
        <Menu username={user.username}/>        
        <App/>
      </Route>
    </Switch>
    </HashRouter>        
        
    </div>
  ) : ( 
    <div>
    
    <Navbar bg="light" variant="light" className="mb-5">
    <Navbar.Brand href="#home"><img src={R} width="30" height="30" />  </Navbar.Brand>
    <Nav className="mr-auto">      
      <Nav.Link href="https://transcribe.refacturing.com">Home</Nav.Link>
      <Nav.Link href="https://docs.refacturing.com">Docs & Support</Nav.Link>
  
    </Nav>     
  </Navbar>
    <div className="payoff">
    <span className="payoff-main">We use AI to automatically transcribe your audio in different languages</span>
    <span className="payoff-sub">create an account if you haven't already or register to log in</span>
     </div>
    <AmplifyAuthenticator>
    <AmplifySignUp
        slot="sign-up"
        usernameAlias="email"
        formFields={[          
          {
            type: "email",
            label: "Email",
            placeholder: "email where you receive the code to unlock the app",
            required: true,
          },
          {
            type: "password",
            label: "Password",
            placeholder: "Enter password with at least one uppercase letter and one number",
            required: true,
          },          
          {
            type: "custom:consenso",
            label: "I declare that I have read the Privacy policy and authorize the collection of my data",
            placeholder: "write the string SI",
            required: true,
          },
        ]} 
        
    />
    
    <AmplifySignIn slot="sign-in" usernameAlias="email" />
    <AmplifyForgotPassword
    headerText="Enter your email"
    slot="forgot-password"
    usernameAlias="email"
    ></AmplifyForgotPassword>
  </AmplifyAuthenticator>
  
  <footer>I have read the <a target="_blank" rel="noopener noreferrer" href="https://docs.refacturing.com/privacy.html">privacy policy</a> present in the documentation of Transcribe
  </footer>
  <AudioSample />
  <PromoVideo />  
  <SitePlan />  
  <Newsletter />
  <TeamSection />
  <ContactForm />
  <BigFooter />  
  </div>
  
);    
    
}

export default AuthStateApp;