import React from 'react'
import './SitePlan.css'
import { PricingTable, PricingSlot, PricingDetail } from 'react-pricing-table';

function SitePlan() {

  const submit = () => {
    var top = document.getElementById("menu");
    window.scrollTo(0, top);
  }
  return (
    <div className="SitePlan_container">
      <h1 className="text-center mt-5 text-white">Pricing</h1>
      <div className="SitePlanTable">
        <PricingTable highlightColor='#1976D2'>
          <PricingSlot onClick={() => submit()} buttonText='SIGN UP' title='PACK 5' priceText='10 €'>
            <PricingDetail> <b>5</b> hours</PricingDetail>
            <PricingDetail> up to <b>150 MB*</b></PricingDetail>
          </PricingSlot>
          <PricingSlot highlighted buttonText='SIGN UP' onClick={() => submit()} title='PACK 10' priceText='15 €'>
            <PricingDetail> <b>10</b> hours</PricingDetail>
            <PricingDetail>up to <b>300 MB*</b></PricingDetail>
          </PricingSlot>
          <PricingSlot onClick={() => submit()} buttonText='SIGN UP' title='PACK 20' priceText='28 €'>
            <PricingDetail> <b>20</b> hours</PricingDetail>
            <PricingDetail> up to <b>600 MB*</b></PricingDetail>
          </PricingSlot>
        </PricingTable>
        <div onClick={() => window.open('https://docs.refacturing.com/', "_blank")}>*More about pricing</div>
      </div>
    </div>
  )
}
export default SitePlan;