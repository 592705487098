/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:b9c1dddc-47fa-453c-8ba1-117bbe9ef511",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_A0GHRedIh",
    "aws_user_pools_web_client_id": "2dqagm9qcqr47m70f6md0i2kmv",
    "oauth": {},
    "aws_user_files_s3_bucket": "part3storageanalytic2a33ddadadf54d079b567fd4669103810-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://q3dmygo2lbgfffzfz6x4erj4dq.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-zt7vkt4zhndwfl2z7hjvozx3yu",
    "aws_cloud_logic_custom": [
        {
            "name": "api4815c25e",
            "endpoint": "https://qpll99m73g.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "apia7bd66c2",
            "endpoint": "https://xf74gjem27.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "api1e6d807a",
            "endpoint": "https://nr0xkfw1bh.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ]
};


export default awsmobile;
