import React, { useState } from 'react'
import './Newsletter.css'

function Newsletter(){
  const [email,setEmail] = useState('')
  const [name,setName] = useState('')
  return(
    <div className="Newsletter_container">    
<div id="mc_embed_signup">
<h1 className="text-center mt-5 text-white">Newsletter</h1>
<form action="https://gmail.us6.list-manage.com/subscribe/post?u=7e67db2fb2bc40a09d8da0e4b&amp;id=c33e0dfc61" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
    <div id="mc_embed_signup_scroll">
      <div className="indicates-required">
        <span className="asterisk">*</span> indicates required
      </div>
      <div className="mc-field-group">
        <label htmlFor="mce-NAME">Name  <span className="asterisk">*</span>
      </label>
        <input type="text" name="NAME" className="required" id="mce-NAME" onChange={(e) => setName(e.target.value)}/>
      </div>
      <div className="mc-field-group">
        <label htmlFor="mce-EMAIL">Email Address  
          <span className="asterisk">*</span>
        </label>
        <input type="email" name="EMAIL" onChange={(e) => setEmail(e.target.value)} className="required email" id="mce-EMAIL" />
      </div>
      <div id="mergeRow-gdpr" className="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group">
        <div className="content__gdpr">
          <label>Marketing Permissions</label>
          <p>Please select all the ways you would like to hear from Transcribe Team:</p>
          <fieldset className="mc_fieldset gdprRequired mc-field-group" name="interestgroup_field">
		<label className="checkbox subfield" htmlFor="gdpr_65194">
      <input type="checkbox" id="gdpr_65194" name="gdpr[65194]" value="Y" className="av-checkbox gdpr" />
      <span>Email</span> 
    </label>
    <label className="checkbox subfield" htmlFor="gdpr_65198"><input type="checkbox" id="gdpr_65198" name="gdpr[65198]" value="Y" className="av-checkbox gdpr" /><span>Direct Mail</span> </label><label className="checkbox subfield" htmlFor="gdpr_65202"><input type="checkbox" id="gdpr_65202" name="gdpr[65202]" value="Y" className="av-checkbox gdpr" /><span>Customized Online Advertising</span> </label>
        </fieldset>
        <p>You can unsubscribe at any time by clicking the link in the footer of our emails.</p>
    </div>    
</div>
	<div id="mce-responses" className="clear">
		<div className="response" id="mce-error-response" style={{display:'none'}}></div>
		<div className="response" id="mce-success-response" style={{display:'none'}}></div>
	</div>    
    <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
    <input type="text" name="b_7e67db2fb2bc40a09d8da0e4b_c33e0dfc61" tabIndex="-1"/>
    </div>
    <div className="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" /></div>
    </div>
</form>
</div>

</div>
  )
}
export default Newsletter