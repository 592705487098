import React, {useEffect, useState } from "react";
import "./App.css";

//amplify components
import { Auth, Storage, API, graphqlOperation  } from "aws-amplify";
import "@aws-amplify/ui/dist/style.css";

//other elements
import Plans from './components/Plans'
import config from './config'
import LoaderButton from './components/LoaderButton'
import helpers from './utilities/Helpers'
import Uploads from './components/Uploads'
import { v4 as uuidv4 } from 'uuid';

//subscription mutations queries
import * as subscriptions from './graphql/subscriptions';
import * as mutations from './graphql/mutations'
import * as queries from './graphql/queries'

//style
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

//notification
import Swal from 'sweetalert2'

//file upload
import {useDropzone} from 'react-dropzone';
import { Button } from 'react-bootstrap';

//material ui autocomplete
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

//tag manager
import TagManager from 'react-gtm-module'

function App({authState}) {


  const [user,setUser] = useState("")
  const [fileName,setFileName] = useState("")
  const [file,setFile] = useState(null)
  const [metadata,setMetadata] = useState({})
  const [loading,setLoading] =useState(null)
  const [plan,setPlan] = useState({})
  const [uploads,setUploads] = useState([])
  const [currentCoins,setCurrentCoins] = useState(1)
  const [language,setLanguage] = useState({ code: 'it-IT', label: 'Italian' })
  
  const [uploadSectionClassName,setUploadSectionClassName] = useState("")
 
  const listUploads =  async() => {
    
    const uploads = await API.graphql(graphqlOperation(queries.listUploads))
    setUploads(uploads.data.listUploads.items)
  }
  const loadPlan = async() => {
    
    const plan = await API.graphql(graphqlOperation(queries.listPlans, {filter: {coins: {gt: 0}}}))
    if(plan.data.listPlans && plan.data.listPlans.items[0]){  
      setPlan(plan.data.listPlans.items[0])
    }
    
  }
  const loadUser = async() =>{
    try {
      const user = await Auth.currentUserInfo();
      const tagManagerArgs = {
        dataLayer: {
            userId: user ? user.username : '',
            page: 'app'
        },
        dataLayerName: 'PageDataLayer'
      }
      TagManager.dataLayer(tagManagerArgs)
      setUser(user)     
    }
    catch (err) {
      //TODO
      console.log("error getting user: ", err);
    }
  }

  useEffect(() => {
      loadUser()
      listUploads()      
      if(helpers.isEmpty(plan)){
        loadPlan()
        
      }
      
  },["plan","user"])
  
  const updatePlanCoins = async(coins) => {
    await API.graphql(graphqlOperation(mutations.updatePlan, {input: {
      id:plan.id,
      coins:plan.coins - coins
    }}))
    setPlan({...plan, coins: plan.coins - coins }); 
  };

  const saveMetadata =  async(fileName, newfileName) => {
    
    const saveData = await API.graphql(graphqlOperation(mutations.createUpload, {input: {
      owner:user.username,
      key:newfileName, 
      fileName:fileName,
      uploadPlanId:plan.id,
      email:user.attributes.email
    }}))
    if(saveData){
      listUploads()
      setUploadSectionClassName("");
      setFile(null);
      setFileName("");
    }

  }

  const planSubscription = async () => {    
    planSubscription1 = await API.graphql(
      graphqlOperation(subscriptions.conCreatePlan)
        ).subscribe({
          next: (data) => {
            loadPlan()            
          }
    });  
  }
  const uploadSubscription = async () => {    
    uploadSubscription1 = await API.graphql(
      graphqlOperation(subscriptions.conUpdateUpload)
        ).subscribe({
          next: (data) => {
            listUploads()            
          }
    });  
  }

  let uploadSubscription1;
  let planSubscription1;

  useEffect(() => {
    uploadSubscription()
    planSubscription()
    return () => {
      uploadSubscription1.unsubscribe();
      planSubscription1.unsubscribe();
    }

  },[])

  const handleSubmit = async(event) => {
    event.preventDefault();
    if(currentCoins>plan.coins){
      Swal.fire(
        'Upgrade Plan!',
        `Transcription requires ${currentCoins} credits, your plan has ${plan.coins} credits`,
        'warning'
      )
        return;
    }

      if(file){
      const size = file.size;
      
      if (size && size > config.MAX_ATTACHMENT_SIZE) {
        alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`);
        return;
      }
      
      setLoading(true);
      
      const newfileName = language.code + "_" + user.username.replace('@',"_").replace('.','') + "_" + uuidv4() + "_" + fileName.replace(/ /g,"_").replace(/[^a-zA-Z0-9 ]/g, "") + '.mp3';
    
      Storage.put(newfileName, file,{metadata:{owner:user.username}}).then(() => {
        saveMetadata(fileName,newfileName)
        updatePlanCoins(currentCoins)
        setLoading(false)               
        Swal.fire(
          'Upload completed!',
          'Now relax that we are transcribing, it will be our pleasure to send you a notice at the end of the transcription',
          'success'
        )
                
      })
    }else{
      Swal.fire(
        'Please select a valid file',
        'An mp3 file with a maximum size of 150MB',
        'warning'
      )
    }
  }
  
  const handleUpdateGettoni = (plan) => {
    setPlan(plan)
  }
  
  
  //TODO setUploadSectionClassName
  const {getInputProps,getRootProps,acceptedFiles} = useDropzone({
    accept:'audio/mpeg',  
    maxFiles:1,  
    // Note how this callback is never invoked if drop occurs on the inner dropzone
    onDropRejected: files => {
      Swal.fire(
        'Pay attention!',
        'You only have to upload one file at a time, mp3 format with size less than 150MB',
        'warning'
      )
    },
    maxSize:config.MAX_ATTACHMENT_SIZE,
    onDropAccepted: files => {
      //
    
    const file = files[0]
    const size = file.size;
    const sizeMB = Math.round(size/(1024*1024))
  
    //coins 
    let currentCoins = 1;
    if(sizeMB >= 30 && sizeMB <60){
      currentCoins = 2
    }else{
      if(sizeMB >= 60 && sizeMB <90){
        currentCoins = 3
      }else{
        if(sizeMB >= 90 && sizeMB <120){
          currentCoins = 4
        }else{
          if(sizeMB >120){
            currentCoins = 5
          }
        }
      }
    }
    if(plan.coins<=0){
      setPlan({})      
    }
    if(file && file.name){
      setFileName(file.name);
      setUploadSectionClassName("hidden");
      const metadata = {
        owner: user.username      
      }
      setFile(file)
          
      setMetadata(metadata)
      setCurrentCoins(currentCoins)
    }
    }
  });
  const  handleAnnulla = (e) => {
    e.preventDefault();
    setUploadSectionClassName("");
    setFile(null);
    setFileName("");
  }
  //autocomplete 
  const supported_languages = [
    { code: 'ar-AE', label: 'Gulf Arabic' },
    { code: 'ar-SA', label: 'Modern Standard Arabic'},
    { code: 'zh-CN', label: 'Mandarin Chinese – Mainland'},
    { code: 'nl-NL', label: 'Dutch' },
    { code: 'en-AU', label: 'Australian English' },
    { code: 'en-GB', label: 'British English' },
    { code: 'en-IN', label: 'Indian English' },
    { code: 'en-IE', label: 'Irish English' },
    { code: 'en-AB', label: 'Scottish English' },
    { code: 'en-US', label: 'US English' },
    { code: 'en-WL', label: 'Welsh English' },
    { code: 'es-ES', label: 'Spanish' },
    { code: 'es-US', label: 'US Spanish' },
    { code: 'fr-FR', label: 'French' },
    { code: 'fr-CA', label: 'Canadian French' },
    { code: 'fa-IR', label: 'Farsi Persian' },
    { code: 'de-DE', label: 'German' },
    { code: 'de-CH', label: 'Swiss German' },
    { code: 'he-IL', label: 'Hebrew' },
    { code: 'hi-IN', label: 'Indian Hindi' },
    { code: 'id-ID', label: 'Indonesian' },
    { code: 'it-IT', label: 'Italian' },
    { code: 'ja-JP', label: 'Japanese' },
    { code: 'ko-KR', label: 'Korean' },
    { code: 'ms-MY', label: 'Malay' },
    { code: 'pt-PT', label: 'Portuguese' },
    { code: 'pt-BR', label: 'Brazilian Portuguese' },
    { code: 'ru-RU', label: 'Russian' },
    { code: 'ta-IN', label: 'Tamil' },
    { code: 'te-IN', label: 'Telugu' },
    { code: 'tr-TR', label: 'Turkish' }
  ]
 
  // end autocomplete
    return (
      <div className="App">      
        <div className="Layout">
        

          <div className="PlansUploadContaniner corpo">
          

            <h1>{authState}</h1>
            <Plans className="AppPlans" activePlan={plan} activeUser={user.username}
            
            updateApp={(cc) => {handleUpdateGettoni(cc)}}/>        
            {
              helpers.isEmpty(plan) || plan.coins<= 0 ? (
                null
              ) : 
              <div className="App_Uploads">
                <h6>Active plan | credits available {plan.coins}</h6>
                <div className="FileName">{fileName}</div>
                <section className={`container ${uploadSectionClassName}`}>
                  <div {...getRootProps({ className: 'dropzone' })} >
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop one file here, or click to select files</p>
                    <em>(Only *.mp3 will be accepted)</em>
                  </div>
                  <aside>
                    
                  </aside>
                </section>
                <div>
                  <h6 className="mt-3">audio language:</h6>
                    <h6><strong>{language.label} active</strong></h6>
                    <Autocomplete
                    value={language}
                    onChange={(event, newValue) => {
                      if(newValue){
                        setLanguage(newValue);
                      }                      
                    }}
                    id="country-select-demo"
                    style={{ width: 300 }}
                    options={supported_languages}
                    style={{
                      margin: '0 auto',
                      maxWidth: 800
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option.label ? option.label : ""}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.label} ({option.code})
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a language"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                  



                  
                </div>
                <Button className="btn btn-secondary mr-3 mt-3"
                onClick={handleAnnulla} disabled={loading}
                >Cancel</Button>
                <LoaderButton            
                type="submit"
                isLoading={loading}
                text="Upload!"
                loadingText="Uploading..."
                onClick={handleSubmit}
                />                           
            </div>
            
            }
            <Uploads uploads={uploads}/>
            
          </div>            
        </div>
      </div>

    );
}

export default App;
