import React, {useState} from 'react'
import { Button, Card,OverlayTrigger,Tooltip } from 'react-bootstrap';
import Riccardo from '../images/riccardo_mancinelli.png'
import Fabio from '../images/fabio_bruno.jpeg'
import Chiara from '../images/chiara_bartolozzi.jpeg'
import 'bootstrap/dist/css/bootstrap.min.css';
import './TeamSection.css'


function TeamSection(){
  const [team,setTeam] =useState([
    {
      name: 'Riccardo',
      known:'Riccardo Mancinelli',
      url: 'https://www.linkedin.com/in/ricmancio/',
      image:Riccardo,
      text:`
      CEO CTO & Founder at Refacturing Ltd
      `
    },
    {
      name: 'Fabio',
      known:'Fabio Bruno',
      url: 'https://www.linkedin.com/in/fabruno/',
      image:Fabio,
      text:`
      Host - Producer - Content Creator - Community Manager
      `
    },
    {
      name: 'Chiara',
      known:'Chiara Bartolozzi',
      url: 'https://www.linkedin.com/in/onesectranslations/',
      image:Chiara,
      text:`
      Traduttrice, interprete, copywriter e content writer
      `
    }])

  return(
    <div className="team">
      <h1 className="pt-3 text-dark text-center">Team</h1>
      <ul className="team__ul">
      {
        team.map((t,index) => (
          <li key={index}>
          <Card text="light" border="dark" style={{ width: '20rem', alignItems:'center', paddingTop:'30px', margin:'5px', backgroundColor:"#6d757d"}} className="team__card">
            <Card.Img  src={t.image} className="team__img"/>
            <Card.Body>
              <Card.Title className="team__title">{t.name}</Card.Title>
              <Card.Text  className="team__text">
                {t.text}
              </Card.Text>
              <OverlayTrigger
              key='top'
              placement='top'
              overlay={
                <Tooltip id='tooltip-top'>
                  {t.known}
                </Tooltip>
              }>
              <Button variant="dark" target="_blank" onClick={() => window.open(t.url, "_blank")} className="mt-3 mb-5 team__button" className={t.name}>Linkedin</Button>
              </OverlayTrigger>
            </Card.Body>
          </Card>
          </li>  
        ))
      }
        
      </ul>
    </div>
  )
}

export default TeamSection