import React,{useState,useEffect} from 'react';
import Plan from './Plan'
import './Plans.css'
import {API} from "aws-amplify";
import helpers from '../utilities/Helpers'
import { Accordion,Card,Button } from 'react-bootstrap';
import Swal from 'sweetalert2'

function Plans({activePlan,activeUser,updateApp}){

  const [plans,setPlans] = useState([
    {
      id:1,
      name:"5 credits plan",
      coins:5,
      owner:activeUser    
    },
    {
      id:2,
      name:"10 credits plan",
      coins:10,
      owner: activeUser
    },
    {
      id:3,
      name:"20 credits plan",
      coins:20,
      owner: activeUser
    }
  ]);


  const [plan, setPlan] = useState({});

 
  useEffect(() => {
    
    if(helpers.isEmpty(activePlan) || activePlan.coins <=0){
            
    }else{
      if(activePlan){
        setPlan(activePlan)   
      }
    }
    
  })

  
  const refreshComponent = (data) => {
    setPlan(data);
    Swal.fire(
      'Plan activated',
      'All you have to do is upload an audio file!',
      'success'
    )
  }
  const updateGettoniFromPlans = (plan) => {
    updateApp(plan)
  }
  //todo view piani + view piano attivo
  return (
    <div className="Plans">
    <Accordion defaultActiveKey="0">
  <Card>
    <Card.Header className="CardGettoniHeader">
      <Accordion.Toggle as={Button} variant="link" eventKey="1">
        Credits
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="1">
      <Card.Body>  
        <div className="Plans-container" >
            {
              (plans.map(plan => {
              return (                
                <Plan data={(cc) => refreshComponent(cc)} key={plan.id} name={plan.name} id={plan.id} coins={plan.coins} username={activeUser} existPlan={activePlan} updatePlans={(cc) => {updateGettoniFromPlans(cc)}}/>
              )
            })) 
            }
          </div>
      </Card.Body>
    </Accordion.Collapse>
  </Card>
</Accordion>
</div>         
  )
}

export default Plans;