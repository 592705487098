import React,{useState} from 'react';
import './Plan.css'
import {API, graphqlOperation  } from "aws-amplify";

import * as mutations from '../graphql/mutations';
import { Button , Card} from 'react-bootstrap';
import Swal from 'sweetalert2'

//stripe
import Layout from "./StripeIntegration";
import CheckoutForm from "./CheckoutForm";
import Modal from 'react-bootstrap/Modal'
import helpers from '../utilities/Helpers'

function Plan({id,coins,name,username,existPlan,data,updatePlans}){
  
  const [show,setShow] = useState(false)
  const [price,setPrice] = useState(4)
  const [credits,setCredits] = useState(0)
  
  const handleClose = () => setShow(false);
  
  //stripe end

  const updatePlan = async ()=>{
    //existPlan credits
    
    const result = await API.graphql(graphqlOperation(mutations.updatePlan,{input:{
      owner:username,
      id:existPlan.id,
      coins: existPlan.coins +  credits
    }}))

    updatePlans(result.data.updatePlan)
  }
  const handleSubmit = async (e,id) => {

    
    //todo chiamata BE per validazione coupon owner e duedate
    e.preventDefault();
    //ho un piano attivo?
    const isActivePlan =  helpers.isEmpty(existPlan) ? false : true
    const priceCredits = helpers.getPrice(id)
        
    setCredits(priceCredits.credits)
    setPrice(priceCredits.price)
    setShow(true);
    
    
  }
  
  const createPlan = async () => {
            
      const planCreated = await API.graphql(graphqlOperation(mutations.createPlan,{input:{
        owner:username,
        coins:credits,
        planCoins: credits
      }}))
      
      if(planCreated.data.createPlan){
        data(planCreated.data.createPlan);        
      }
      
  }
  
  const successHandle = ()=>{
    const isValidPlan = helpers.isEmpty(existPlan) ? createPlan() : updatePlan()    
    
    handleClose();
    Swal.fire(
      'Thanks!',
      'Credits have been added',
      'success'
    )
  }
  return(
    <div>
      <Card className="Plan">
        <h3 className="PlanCoins">{coins} credits</h3>        
        <Button style={{"marginTop":"12px"}} onClick={(e) => handleSubmit(e,id)}>Update plan</Button>
      </Card>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add {credits} credits</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Layout title="buy">
          <CheckoutForm
            price={price}
            credits={credits}
            onSuccessfulCheckout={() => successHandle()}
          />
        </Layout>
        
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Plan;