import React from 'react'
import './ContactForm.css'
import { ErrorMessage } from 'formik';
import { useFormik } from 'formik';
import * as Yup from "yup";
import Swal from 'sweetalert2'

function ContactForm(){

  const formik = useFormik({

    initialValues: {
      name: '',
      email: '',
      message: '',
    },
    validationSchema:Yup.object({
      name: Yup.string()
        .min(2, 'Name Too Short!')
        .max(70, 'Name Too Long!'),
      email: Yup.string()
        .email('Invalid email')
        .required('Email Required'),
      message: Yup.string()
        .min(5, 'Message Too Short!')      
        .required('Message Required'),
    }),
    onSubmit: async (values, {resetForm}) => {
      
      const rawResponse = await fetch('https://hook.integromat.com/cf3sdkt2t1seq1niu3a9bmhhu4g1rnh4',{
          method:'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(values, null, 2)
        })
        if(rawResponse.status === 200){
          Swal.fire(
            'Thanks!',
            'Your message has been delivered',
            'success'
          )
          resetForm();
        }else{
          Swal.fire(
            'There was a problem!',
            'try later',
            'warning'
          )
        }

        
        
    },

  });

  return (
    <div>
    <h1 className="text-center mt-5">Contact us</h1>
    <form onSubmit={formik.handleSubmit} id="contact-form">
      <label htmlFor="name">Name</label>
      <input
        id="name"
        name="name"
        type="text"
        onChange={formik.handleChange}
        value={formik.values.name}
        className="form-control"
      />
      {formik.touched.name && formik.errors.name ? (
        <div className="alert alert-danger">{formik.errors.name}</div>
      ) : null}
      <label className="mt-3" htmlFor="email">Email</label>
      <input
        id="email"
        name="email"
        type="email"
        onChange={formik.handleChange}
        value={formik.values.email}
        className="form-control"
      />
      {formik.touched.email && formik.errors.email ? (
        <div className="alert alert-danger">{formik.errors.email}</div>
      ) : null}
      <label className="mt-3" htmlFor="message">Message</label>
      <textarea
        id="message"
        name="message"
        onChange={formik.handleChange}
        value={formik.values.message}
        className="form-control"
      />
      {formik.touched.message && formik.errors.message ? (
        <div className="alert alert-danger">{formik.errors.message}</div>
      ) : null}
      <p className="mt-3">By clicking the button below, you allow Transcribe Team to store and use the information to fulfill your request. For more information see our <a href="https://docs.refacturing.com/privacy.html" target="_blank">privacy policy</a></p>
      <div className="row justify-content-center">
      <button className="btn btn-primary mt-5" type="submit">Contact</button>
      </div>
    </form>
    </div>
  );
}

export default ContactForm