import React, { useEffect, useState } from 'react'
import { TranscriptEditor } from "@bbc/react-transcript-editor";
import helpers from '../utilities/Helpers'
import {Form , Button} from 'react-bootstrap';
import './AudioSample.css'
import Swal from 'sweetalert2'
import * as moment from 'moment'
import ReactDOM from 'react-dom';
import italianSample from '../data/01.json'
import enUSSample from '../data/02.json'

//tag manager
import TagManager from 'react-gtm-module'

function AudioSample(){
  const [edit,setEdit] = useState(false)
  const tagManagerArgs = {
    dataLayer: {
        page: 'home',
        section:'editor-sample'
    },
    dataLayerName: 'PageDataLayer'
  }
  TagManager.dataLayer(tagManagerArgs)

  const [transcript,setTranscript] = useState("")
  const [samples,setSamples] = useState([{id:1,text:'Italian sample'},{id:2,text:'US English sample'}])

  const [mediaUrl,setMediaUrl] = useState("")
  var url_pattern = "/(http[s]?:\/\/)?([^\/\s]+\/)(.*)/";

  const handleChange = async (value) => {
    debugger
    console.log('new value ' + value)    
    
    //aggiungo manualmente i trascript
    if(value.target.value == 'Italian sample'){
      
      setTranscript(italianSample)
      setMediaUrl("https://api.spreaker.com/download/episode/14799119/01.mp3")
    }else{
      if(value.target.value == 'US English sample'){
        setTranscript(enUSSample)
        setMediaUrl("https://api.spreaker.com/download/episode/43734973/how_to_pronounce_50_brand_names_in_english.mp3")
      }else{
        setTranscript("")
        setMediaUrl("")
      }
    }
    
    //
    
  }
  useEffect(() => {
    //todo caricare i samples
    setEdit(true)
  },['samples'])
  
  return(
    <div class="AudioSamples">
   <h1 class="text-center mt-5 text-white">Samples</h1>
    <div className="Editor__container mt-5 mb-3">      
      <p className="mt-5 Editor__intro">
        Choose one transcript raw sample
      </p>      
    {
      samples.length>0 ? 
      (
        
    <Form>
    
      <Form.Group >        
        <Form.Control as="select" id="TranscriptOption" custom
        onChange={(e) => handleChange(e)}
        className="mb-3"      
        >
        <option key="0" value="0">Select transcript</option>
        {
          (samples.map(item => {
          return (                
            <option key={item.id} value={item.text}>{item.text}</option>
            )
          })) 
        }

        </Form.Control>
        
        
      </Form.Group>
     
    </Form>
    
      ) : null
    }
    </div>    
    <TranscriptEditor
    transcriptData={transcript}
    mediaUrl={mediaUrl}
    isEditable={edit}
    spellCheck={true}
    sttJsonType={"amazontranscribe"}
    mediaType="audio"
    className="TranscriptEditor"
    handleAnalyticsEvents={() => {}}
    />
    </div> 
  )
}

export default AudioSample;