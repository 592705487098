import React, {useState } from 'react';
import {API} from "aws-amplify";
import './Uploads.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Moment from 'react-moment';
import Transcript from './Transcript'
import * as moment from 'moment'

function Uploads({uploads}){

  const [transcript,setTranscript] = useState("")
  const [loading,setLoading] = useState(false)
  const [cliccato,setCliccato] =useState(-1)


  const getTranscript = async (key,index) =>{
    setLoading(true)
    setCliccato(index)
    const newKey = key.substring(0, key.lastIndexOf('.')) + ".json";
    //console.log("gettrascript",newKey)
    if(localStorage.getItem(newKey) === null){
      const result = await API.get('apia7bd66c2',`/people?key=${newKey}`)
      //console.log("entrato per vedere result",JSON.stringify(result))
      if(result.results && result.results.transcripts && result.results.transcripts.length>0){      
        setTranscript(result.results.transcripts[0].transcript); 
        localStorage.setItem(newKey, result.results.transcripts[0].transcript);     
      }
    }else{
      setTranscript(localStorage.getItem(newKey));     
    }
    
    setLoading(false)
    
  }

  return (
    <div>
    <Transcript loading={loading} transcript={transcript}/>
    <h5 className={uploads.length>0 ? 'show UploadSectionTitle' :'hidden'}>Upload already done</h5>
    
    {uploads.length === 0 ? <h3 className="mt-5">there are no uploads</h3> : null}
    <div className={uploads.length>0 ? 'show UploadsContainer' :'hidden'}>
    <div className="UploadsItem UploadIntestazione">
      <span className="UploadTime">Data</span>
      <span className="UploadsKeyItem">name</span>
      <span className="status">status</span>
    </div>
    
    {        
        uploads.sort((a,b) => moment(b.createdAt).format('YYYYMMDDhhmm') - moment(a.createdAt).format('YYYYMMDDhhmm')).map((upload,index) => (          
            <div key={index} className="UploadsItem" 
            style={index === cliccato ? {"border":"2px solid lightgreen"} : {"border":"1px solid #ccc"}}>   
            
            <Moment className="UploadTime" format="DD/MM/YYYY hh:mm a">{upload.createdAt}</Moment>
            <span className="UploadsKeyItem">{upload.fileName.substring(0, 24)}</span>
            
            {upload.transcriptStatus === 1  ? <span className="status">completed</span> : <span className="status">pending</span>}
            
            <button className="btn btn-primary transcriptButton" disabled={upload.transcriptStatus === 1  ? false : true} onClick={() => getTranscript(upload.key,index)} target="_blank"><i className="far fa-file-alt"></i>            
            </button>
            
            </div>
            
                    
        ))
    }
    </div>
    </div>
  )
}

export default Uploads;