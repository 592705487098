import React from 'react';
import config from '../config'

//stripe
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(config.STRIPE_PUBLISHABLE_KEY);

const StripeIntegration = ({ children, title }) => {
  return (   
      <div>
      <Elements stripe={stripePromise}>{children}</Elements>
      </div>
  )
}

export default StripeIntegration;