import React from 'react';
import PacmanLoader from 'react-spinners/PacmanLoader'
import Swal from 'sweetalert2'

function Transcript({loading,transcript}){
  
  const copyCodeToClipboard = (e) => {
    var from = document.getElementById("transcript");
      var range = document.createRange();
      window.getSelection().removeAllRanges();
      range.selectNode(from);
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
      Swal.fire('Copied!')
  }
  return (
    <div>
      <div className="centered">{loading && <PacmanLoader size="15"/>}</div>
      <h6 className="TitleTranscriptBox" style={transcript === '' ? {"display":'none'} : {"display":'block'}} >Click in the text to copy it</h6>
      <p id="transcript" className="TranscriptText" style={transcript === '' ? {"display":'none'} : {"display":'block'}}  onClick={copyCodeToClipboard}>{transcript}</p>
    </div>
  )
}

export default Transcript;

