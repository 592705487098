import { API, graphqlOperation  } from "aws-amplify";

import * as subscriptions from '../graphql/subscriptions';
import * as mutations from '../graphql/mutations'
import * as queries from '../graphql/queries'

const priceList = [{id:1,price:10,credits:5},{id:2,price:15,credits:10},{id:3,price:28,credits:20}]

const helpers = {
  isEmpty : (obj) => {
    return Object.keys(obj).length === 0;
  },
  getPrice: (id) => {
    const priceItem = priceList.filter(x => x.id === id)[0]   
    return Object.keys(priceItem).length === 0 ? {
      price:-1,
      credits: 0
    } : {
      price: priceItem.price,
      credits:priceItem.credits
    }
  },
  listUploads: async() => {
    
    const uploads = await API.graphql(graphqlOperation(queries.listUploads))
    return uploads.data.listUploads.items
  },
  getTranscript: async(key) => {
    
    const newKey = key.substring(0, key.lastIndexOf('.')) + ".json";
    
    if(localStorage.getItem(newKey) === null){
      const result = await API.get('apia7bd66c2',`/people?key=${newKey}`)
      //console.log("entrato per vedere result",JSON.stringify(result))
      if(result.results && result.results.transcripts && result.results.transcripts.length>0){      
        return result
      }
    }else{
      return localStorage.getItem(newKey)     
    }
  }
  
}

export default helpers;