import React from 'react'
import './PromoVideo.css'

function PromoVideo(){

  return(
    <div className="PromoVideo mt-5 mb-5">
    <h1 className="mb-5">How does it work</h1>
    <iframe  src="https://www.youtube.com/embed/u1sLEe_VMHw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
    </div>
  )

}
export default PromoVideo