/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPlan = /* GraphQL */ `
  query GetPlan($id: ID!) {
    getPlan(id: $id) {
      id
      owner
      coins
      planCoins
      coupon {
        id
        owner
        tokens
        valid
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      uploads {
        nextToken
      }
    }
  }
`;
export const listPlans = /* GraphQL */ `
  query ListPlans(
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        coins
        planCoins
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCoupon = /* GraphQL */ `
  query GetCoupon($id: ID!) {
    getCoupon(id: $id) {
      id
      owner
      tokens
      valid
      plan {
        id
        owner
        coins
        planCoins
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCoupons = /* GraphQL */ `
  query ListCoupons(
    $filter: ModelCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoupons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        tokens
        valid
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUpload = /* GraphQL */ `
  query GetUpload($id: ID!) {
    getUpload(id: $id) {
      id
      key
      fileName
      owner
      plan {
        id
        owner
        coins
        planCoins
        createdAt
        updatedAt
      }
      transcriptStatus
      createdAt
      updatedAt
    }
  }
`;
export const listUploads = /* GraphQL */ `
  query ListUploads(
    $filter: ModelUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUploads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        fileName
        owner
        transcriptStatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
