import React from 'react';
import { Button } from 'react-bootstrap';
import './LoaderButton.css';

function LoaderButton({ isLoading, text, loadingText, className = '', disabled = false, ...props }){
  return(
    <Button className={`LoaderButton ${className} mt-3`} disabled={disabled || isLoading} {...props}>    
		{!isLoading ? text : loadingText}
    </Button>
  )
}
export default LoaderButton;