import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Amplify, { Storage } from 'aws-amplify';
import awsconfig from './aws-exports';
import AuthStateApp from './AuthStateApp';

Amplify.configure(awsconfig);
Storage.configure({ level: 'private' });

ReactDOM.render(<AuthStateApp />, document.getElementById('root'));
serviceWorker.unregister();
