import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Auth  } from "aws-amplify";
import {AmplifySignOut} from '@aws-amplify/ui-react';
import { Navbar,Nav,Form,FormControl,Button } from 'react-bootstrap';
import {Link} from 'react-router-dom'
import './Menu.css'

function Menu({username}){
  const [user,setUser] = useState("")
  const loadUser = async() =>{
    try {
      const user = await Auth.currentUserInfo();
      setUser(user)     
    }
    catch (err) {
      //TODO
      console.log("error getting user: ", err);
    }
  }
  useEffect( () => {
     loadUser()
  },[])
  
  return(

    <Navbar bg="light" expand="lg" id="menu">
      <Navbar.Brand href="#home">Hello, {user.username}</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mr-auto">
        <Nav.Link href="#/app">Transcript</Nav.Link>
        <Nav.Link href="#/editor" className="editor__nav_link"><span className="badge">Beta </span>Editor</Nav.Link>
        <Nav.Link href="https://docs.refacturing.com" target="_blank">Docs & Support</Nav.Link>
      </Nav>
    
      </Navbar.Collapse>
      <div className="topnav-right">
        <AmplifySignOut />
      </div>
    </Navbar>
      
    
  )
}

export default Menu;