import React from 'react'
import { Navbar,Nav,Form,FormControl,Button } from 'react-bootstrap';

function BigFooter(){
  return(
    <footer className="mb-5 mt-5">
    <Nav className="mr-auto">
      <Nav.Link href="https://transcribe.refacturing.com">Home</Nav.Link>
      <Nav.Link href="https://docs.refacturing.com" target="_blank">Docs & Support</Nav.Link>
      <Nav.Link href="https://docs.refacturing.com/privacy.html" target="_blank">Privacy</Nav.Link>
    </Nav>
    </footer>
  )
}

export default BigFooter